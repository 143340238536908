import './App.css';
import { useEffect, useState } from 'react';
import { signInWithPopup, GoogleAuthProvider, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from './firebase/firebaseConfig';
import { useSearchParams } from 'react-router-dom';

function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (result) => {
      if (result) {
        const { displayName, email } = result;
        setUserData({ displayName, email });
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const SignUpUsingGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        const { displayName, email } = result.user;
        setUserData({ displayName, email });

        setIsLoggedIn(true);
        const date = new Date();
        const encryptedEmail = btoa(email + ',' + date.toDateString());
        const encryptedDate = btoa(date.toDateString());

        let appId;
        switch (searchParams.get("appid")) {
          case 'solar':
            appId = 'embedded/public/20044ec2-0bb1-4a9d-8c60-c16e8e074834';
            break;
          case 'roof':
            appId = 'p/roof-wizard';
            break;
            case 'water':
            appId = 'p/wizard-water-filter';
            break;
          default:
            appId = 'p/hvac_calculator';
            break;
        }

        const targetUrl = `https://volthome.retool.com/${appId}?uid=${encodeURIComponent(encryptedEmail)}&did=${encodeURIComponent(encryptedDate)}`;
        console.log("Redirecting to:", targetUrl);
        window.location.href = targetUrl;
      })
      .catch((error) => {
        console.log("Error during sign-in:", error);
      });
  };

  return (
    <div className="App">
      <button onClick={SignUpUsingGoogle} type="button" className="login-with-google-btn">
        Go to wizard
      </button>
    </div>
  );
}

export default App;
